$primary: #4d4fff;
$secondary: #ff66b3;
$last: #f4b942;

$desktop: 1024px;
$mobile: 1022px;
$siteWidth: 1500px; 

@mixin respond-to($media) {
    @if $media ==desktop {
        @media (min-width: $desktop) {
        @content;
        }
    }

    @else if $media ==mobile {
        @media (max-width: $mobile) {
        @content;
        }
    }
}

.page{
    min-height: clamp(500px, 74vh, 80vh);
}
.contact-btn-1 {
    background-color: $secondary!important;
    min-width: 140px;
    font-size: 1.2em!important;
    transition: transform 0.19s ease, box-shadow 0.2s ease!important;
}

// buttons
.btn-main {
    background-color: $primary!important;
    color: white;
    min-width: 38%;
    padding: 10px;
}
.bf-card{
    svg{
        color: $secondary!important;
    }
}
.animated{
    @include respond-to(desktop) {
        transition: transform 0.19s ease, box-shadow 0.2s ease;
        z-index: 1;

    }
}
.animated:hover {
    @include respond-to(desktop) {
        z-index: 50;
        transform: scale(1.1);
    }
}

.animated-bounce:active {
    transform: scale(0.95);
    animation: bounced 0.3s ease-in-out;
}

@keyframes bounced {
    0% {
        transform: scale(0.95);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

 

.text-banner{
    background-color: $primary;
    width: 100%;
    min-height: 80px;
    z-index: 80;
}
.svgicon{
    display: flex;
    align-items: center;
    width: 100%;
    @include respond-to(desktop) {

        justify-content: center;
    }
     
    @include respond-to(mobile) 
    {
        justify-content: start;

    }
    svg{
        height: 100%;
        width: 100%;
        max-height: 35px;
        max-width: 35px;
        @include respond-to(mobile) 
        {
            width: auto!important;
            
        }
    }
}
.btn-last{
    background-color: $last!important;
    color: black;
}

.scroller-container {
    background-color: $primary!important;
 }

 

@keyframes scroll {
    from {
        transform: translateX(100%); /* Start from the right */
    }
    to {
        transform: translateX(-100%); /* Scroll to the left */
    }
}




.cases-scroller {
 
  
  .case {
    position: relative;
 
    img {
      display: block;
      width: 100%!important;
      object-fit: cover;
      min-height: 200px;
    }
  
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 20px;
      opacity: 0;
      background: rgba(0, 0, 0, 0.6);
      transition: opacity 0.3s ease;
    }
  
   
  }
}
.img-container2{
    overflow: hidden;
    width: 100%;
    height: 100%;
    .img{
        border-radius: 15px;
    }
}
.submittext{
    @include respond-to(desktop) {
        font-size: 1.25rem!important;

    }
     
    @include respond-to(mobile) 
    {
        font-size: .9rem!important;
    }
}
.privacy-policy{
    .h3h{
        margin-top: 23px;
        margin-bottom: 7px;

    }
    
    h1{
        font-weight: bolder!important;
        @include respond-to(desktop) {
            font-size: 2.25rem!important;

        }
        margin-bottom: 10px;
        @include respond-to(mobile) 
        {
            font-size: 1.1rem!important;
        }
    }
}
 