$primary: #4d4fff;
$secondary: #ff66b3;
$last: #f4b942;

$desktop: 1024px;
$mobile: 1022px;
$siteWidth: 1500px; 

@mixin respond-to($media) {
    @if $media ==desktop {
        @media (min-width: $desktop) {
        @content;
        }
    }

    @else if $media ==mobile {
        @media (max-width: $mobile) {
        @content;
        }
    }
}


html,
#root,
#page-wrap {
    min-height: 100vh;
    overflow-x: hidden;
    @include respond-to(mobile) 
    {
        font-size: 1.05rem;
    }
}

.content{
    @include respond-to(desktop) {
        width: 95%;
    }
    max-width: 1400px;
    margin: auto;
    img{
        border-radius: 15px;
    }
}
.desktop-content{
    @include respond-to(desktop) {
        width: 95%;
        max-width: 1400px;
        margin: auto;
        img{
            border-radius: 15px;
        }
    }
}
* {

    font-family: "Outfit";
}

p {
    @include respond-to(desktop) {
        font-size: clamp(1.18em, 1em + 2 * ((100vw - 300px) / 1300), 5em) !important;


    }
     
    @include respond-to(mobile) 
    {
        font-size: 1rem;
        line-height: 30px;

    }


}

h1,
h2,
h3,
h4,
h5 {
    margin-bottom: .5rem;
    font-weight: 400!important;
    line-height: 1.2em !important;
 
}

.feature {
    h3 {
        color: black !important;
    }

    p {
        color: gray !important;
    }
}

.logo {
    img {
        max-height: 33px;
    }
}

.bg {
    background-color: #e7eefd;
    height: 50px;
}

.nav-head {
    min-height: 100px;
}


.mobile-menu {
    // Container styles
    margin-top: -25px;

    nav {
        display: flex;
        flex-direction: column;
        padding: 1rem; // Adjust padding as needed
        overflow: hidden;

        a {
            padding: 0.5rem 1rem !important; // Padding inside each link
            transition: color 0.3s ease; // Smooth transition for hover effect
            margin-bottom: 0; // Default margin-bottom

            &:hover {
                color: darkgray; // Hover color
            }
        }

        .contact-btn-1 {
            border-radius: 0.25rem;
            display: flex;
            justify-content: center;
            align-items: center;
            
            a {
            
                color: white; // Button link text color
                text-decoration: none; // Remove underline
            }
        }
    }
}
#phoneIcon{
    min-width: 25px;
    min-height: 25px;
}
:root {
    --min-font-size: 1.7em; /* Minimum font size */
    --max-font-size: 3em; /* Maximum font size */
}
.nobg{
    background-color: transparent!important;
}
.title {
    font-size: clamp(var(--min-font-size), calc(1em + 30 * ((100vw - 300px) / 1300)), var(--max-font-size)) !important;
}
.new-title {
    @include respond-to(desktop) {
        font-size: 3rem;
    }
     
    @include respond-to(mobile) 
    {
        font-size: 31px;
        line-height: 40px;

    }
}
 
.title2 {
    font-size: calc(1.1em + 14*(100vw - 300px)/1300) !important;
}
.title {
    line-height: 1.2em;
}

.container {
    max-width: 1318px;

    h1 {
        font-weight: bold;
        line-height: 1.14em !important;
    }

    padding-left: 22px;
    padding-right: 22px;
}

/* src/Header.css */
.header {
    font-size: clamp(1.28em, 1em + 2 * ((100vw - 300px) / 1300), 5em) !important;
    
    width: 100%;
    transition: all 0.3s ease-in-out;
    min-height: 100px;
}

a {
    text-decoration: none !important;


}

.header.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    /* Make sure the header is above other content */
    animation: bounce 0.6s ease-out;
}

@keyframes bounce {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }

    50% {
        transform: translateY(8%);
        opacity: 1;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.mt-30 {
    margin-top: 7.5em;
}

.color-primary {
    color: $primary;
}

.color-secondary {
    color: $secondary;
}

.color-last {
    color: $last;
}

.pack-iconsvg {
    svg {
        height: 100px;
        width: 100px;
    }
}

.title-default {
    font-family: outfit;
    font-weight: 630;
    font-size: calc(1.5em + (76 - 63) * ((100vw - 300px) / (1600 - 300)));
}

.star {
    svg {
        height: 20px;
        width: 20px;
    }
}


.fpd-5{
    padding-top: 15px!important;
    padding-bottom: 15px!important;
    padding-right: 23px!important;
    padding-left: 23px!important;

}
h5 {
    font-weight: 400;
    font-size: 1.25rem;
}

.card1 {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: rgba(34, 34, 34, .11) 0px 14px 14px 2px;
    margin-top: 10px;
    margin-bottom: 20px;
    border: 1.2px solid #e8e8e8;
    animation-name: fadeIn;
    animation-duration: .7s;
    animation-fill-mode: both;
    p {
        color: grey;

        span {
            color: rgb(197, 197, 197);
        }
    }
}
.mdcard1{
   @include respond-to(desktop)
   {
        background-color: #fff;
        border-radius: 15px;
        box-shadow: rgba(34, 34, 34, .11) 0px 14px 14px 2px;
        margin-top: 10px;
        margin-bottom: 20px;
        border: 1.2px solid #e8e8e8;
        animation-name: fadeIn;
        animation-duration: .7s;
        animation-fill-mode: both;

        p {
            color: grey;

            span {
                color: rgb(197, 197, 197);
            }
        }
   }
}

.card2 {
    background-color: #fff;
    border-radius: 15px;
    border: 1.2px solid #e8e8e8;
    animation-name: fadeIn;
    animation-duration: .7s;
    animation-fill-mode: both;

    p {
        color: grey;

        span {
            color: rgb(197, 197, 197);
        }
    }
}


// form & input
.rs-picker-default .rs-picker-toggle.rs-btn{
 
    background-color: #eaecf2!important;
    padding: 2%!important   ;

}
.input {
    background-color: #eaecf2!important;
    padding: 2%!important;

}

.bg-last {
    background-color: $last!important;
    color: white!important;
}

.bg-primary {
    background-color: $primary;
    color: white;

}
.btn-bg-primary{
    background-color: $primary!important;
    color: white!important;

}

.bg-secondary {
    background-color: $secondary !important;
    color: white !important;

}

.circle {
    border-radius: 100%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    .whatsapp{
        svg{
            height: 25px;
            width: 25px;
        }
    }
}

.step {
    margin-top: 77px;

    h2 {
        font-weight: 600;
        @media (max-width: 767px) {
            margin-left: 0px;
                
      
            }
      
            // Styles for desktop
            @media (min-width: 768px) {
                margin-left: 25px;
        
            }
    }

    p {
        /* Calculate font-size starting from 15px and scaling up */
        font-size: calc(0.8em + 8*(100vw - 300px)/1300) !important;
        color: rgb(65, 65, 65);
        @media (max-width: 767px) {
        margin-left: 0px;
            
  
        }
  
        // Styles for desktop
        @media (min-width: 768px) {
            margin-left: 25px;
    
        }
    }
}

.cta-btn {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    padding-right: 25px !important;
    padding-left: 25px !important;
}

.page {
    .img-container {

        overflow: hidden;
        /* Hide overflowed parts */
        position: relative;
        // border: 5px solid white;
        /* Add a white border */
        box-sizing: border-box;
        border-radius: 15px;
        // box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

    }
}

// footer

.footer {

    .footer-middle {
        background-color: #4d4fff;

        width: 100%;
        min-height: 420px;
        a{
            color: white;
        }
    }

    background-color: #5b5dff;
    color: white;
    width: 100%;
    min-height: 500px;
    font-weight: 300;
}

.contactCTA{
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 99;
    .question{
        svg{
            height: 30px;
            width: 30px;
        }
    }
    .profile{
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 49px;
    }
}
.bg-main{
    background-color: #ecf0fe;
}

// a.relative {
//     position: relative;
//     display: inline-block;
// }

// a.relative::after {
//     content: '';
//     position: absolute;
//     left: 0;
//     bottom: -2px;
//     height: 2px;
//     width: 10%;
//     background-color: black;
//     transform: scaleX(0);
//     transition: transform 0.3s ease;
// }

// a.relative:hover::after,
// a.relative.active::after {
//     transform: scaleX(1);
// }

.scroller{
    padding: 0px;
    position: relative;
    height: 5px;
    top: 0px;
    z-index: 99;
}
 
.rs-progress-line {

    padding: 0%!important;
    transform: scaleX(102%);
}

.rs-progress-line-inner{
    max-height: 5px;
}
.bg-m2{
    background-color: #f0f8ff;
}
.wlm{
    svg{
        height: 140px;
        width: 140px;
    }
}
.r-50{
    border-radius: 50px;
    
}
.pre-title{
    font-size: clamp(1.5em, 0.5em + 2.2vw, 5em) !important;

}
.dots{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: -48px auto 24px;
 
    @include respond-to(desktop) {
        padding: 80px 64px;

    }
     
    @include respond-to(mobile) 
    {
        padding-top: 50px!important;
        border-radius: 0px;
        padding: 0px 0px;
    }
    text-align: center;
    background: url(../../public/img/dot.png), transparent;
    background-repeat: repeat;
    background-position: 0 0;
    background-size: 35px 35px;
    border: 4px solid #f8f8fa;
}
.primary-btn-1{
    background-color: $secondary!important;
    color: white!important;
    padding-left: 65px!important;
    padding-right: 65px!important;
    padding-top: 30px!important;
    padding-bottom: 30px!important;
    font-size: 1.3em!important;
    box-shadow: rgba(34, 34, 34, .11) 0px 5px 5px 2px;

}
.newBanner{
    display: flex;
    align-items: center;

    @include respond-to(desktop) {
       width: 150vw;
 
    }
     
    @include respond-to(mobile) 
    {
        width: 190vw;
        margin-bottom: -80px;
    }
    height: 46px;
    color: #ffffff;
    background-color: #4d4fff;
    transform: translateX(-300px) rotate(-5deg);
}
 

/* Text scroll */
.scrolling-text  {
    display: flex;
    gap: 32px;
    align-items: center;
    @include respond-to(desktop) {
        animation: Repurpose_scroll__7mkNJ 180s linear infinite;
    }
     
    @include respond-to(mobile) 
    {
        animation: Repurpose_scroll__7mkNJ 180s linear infinite;


    }
    text-wrap: nowrap;
}
 

@keyframes Repurpose_scroll__7mkNJ {
    0% {
        margin-left: 0;
        transform: translateX(0)
    }

    to {
        
        margin-left: -11000px
    }
}
.faq{
    .rs-panel-header{
        :hover{
            background-color: transparent!important;
        }
        background-color: #eef1f4!important;

    }
    /* Override RSuite Accordion panel hover, active, and focus styles */
    .rs-accordion-panel {
        transition: none !important; /* Disable the transition effect */
    }
    .rs-accordion .rs-panel-btn{
        background-color: transparent !important; /* Disable background color */
      border: none !important; /* Optional: Remove border if needed */
      outline: none !important;  
    }
    .rs-accordion .rs-panel-btn:hover,
    .rs-accordion .rs-panel-btn:focus,
    .rs-accordion .rs-panel-btn:active {
      background-color: transparent !important; /* Disable background color */
      border: none !important; /* Optional: Remove border if needed */
      outline: none !important; /* Remove focus outline */
    }
    .rs-panel-btn:active, .rs-panel-btn:focus, .rs-panel-btn:hover {
        background-color: transparent !important; /* Disable background color */
        border: none !important; /* Optional: Remove border if needed */
        outline: none !important;  
    }
}
.feature-panel{
    @include respond-to(desktop) {
        min-height: 500px;


    }
     
    @include respond-to(mobile) 
    {
        min-height: 1000px;

    }
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
}

.light-bg{
    box-shadow: inset 0px 40px 30px #FFFFFF;
    background-color: #fafaff;
    //FCFBFF
}

.card2{
    box-shadow: 15px 18px 0px #E2E2E2;
    border-radius: 10px;
    min-height: 250px;
    min-width: 200px;
    max-width: 400px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    .left{
        background-color: $secondary;
        width: 50%;
        height: 10px;
    }
    .center{
        background-color: $primary;
        width: 50%;
        height: 10px;
    }
    .right{
        background-color: $last;
        width: 50%;
        height: 10px;
    }
}
.img-container{
    width: 500px;
    height: auto;
    overflow: hidden;
    border-radius: 24px;
    img{
        object-fit: cover;
        width: 100%;
        @include respond-to(mobile) 
        {
            transform: translate(0px, 0px);

        }
        @include respond-to(desktop) {

            transform: translate(0px,0px);
        }
    }
}
.bg-2 {
    background-color: #f7f7f8;
}
.mobile-hide{
    @include respond-to(desktop) {
        display: block!important;

    }
     
    @include respond-to(mobile) 
    {
        display: none!important;

    }
}