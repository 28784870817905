.square {
   
    // width: 11vw;
    // height: 24vh;
    border-radius: 15px;
    &.left {
      background-color: #f4b942;
      animation: pulse 5.5s infinite;
      margin-right: -7px;
       

      // Styles for mobile
        @media (max-width: 767px) {
          // Mobile-specific styles here
          // width: 20vw; // Example width for mobile
          // height: 20vh; // Example height for mobile
          // min-width: 50px; // Adjusted min-width for mobile
          // min-height: 50px; // Adjusted min-height for mobile
          max-width: 75px; // Adjusted min-width for mobile
          max-height: 75px; // Adjusted min-height for mobile
        }
        // Styles for laptop
        @media (min-width: 738px) {

          // min-width: 180px;
          // min-height: 200px;
          // width: 11vw;
        }
        // Styles for desktop
        @media (min-width: 1908px) {
          // Desktop-specific styles here
          // width: 11vw; // Example width for desktop
          // height: 24vh; // Example height for desktop
          // min-width: 210px; // Default min-width for desktop
          // min-height: 210px; // Default min-height for desktop
          max-width: 25vh; // Default max-width for desktop
        }
      
      .img-container{
        // width: 145px; /* Set the desired width */
        // height: 150px; /* Set the desired height */
        overflow: hidden; /* Hide overflowed parts */
        position: relative; 
        border: 5px solid white; /* Add a white border */
        box-sizing: border-box;  
        border-radius: 15px;
      }
      img{
            // width: 100%; /* Make the image fill the width */
            // height: 100%; /* Make the image fill the height */
            object-fit: cover; /* Crop the image to fill the container */
            position: absolute; /* Position it absolutely */
            top: 0;
            left: 0;
      }
    }
  
    &.right {
 
      
      // Styles for mobile
      @media (max-width: 767px) {
        // Mobile-specific styles here
        // width: 20vw; // Example width for mobile
        // height: 20vh; // Example height for mobile
        max-width: 70px; // Adjusted min-width for mobile
        max-height: 70px; // Adjusted min-height for mobile
      }

      // Styles for laptop
      @media (min-width: 738px) {

        // min-width: 180px;
        // min-height: 200px;
        // width: 11vw;
      }
      // Styles for desktop
      @media (min-width: 1908px) {
        // Desktop-specific styles here
        // width: 11vw; // Example width for desktop
        // height: 24vh; // Example height for desktop
        // min-width: 210px; // Default min-width for desktop
        // min-height: 210px; // Default min-height for desktop
        max-width: 25vh; // Default max-width for desktop
      }
    
      .img-container{
        // width: 145px; /* Set the desired width */
        // height: 150px; /* Set the desired height */
        overflow: hidden; /* Hide overflowed parts */
        position: relative; 
        border: 5px solid white; /* Add a white border */
        box-sizing: border-box;  
        border-radius: 15px;
      }
      background-color: #4d4fff;
      animation: pulse 5.7s infinite;
      margin-left: -7px;
      img{
        // width: 100%; /* Make the image fill the width */
        // height: 100%; /* Make the image fill the height */
        object-fit: cover; /* Crop the image to fill the container */
        position: absolute; /* Position it absolutely */
        top: 0;
        left: 0;
      }
    }
  
    &.center {
  
      // Styles for mobile
      @media (max-width: 767px) {
        // Mobile-specific styles here
        // min-width: 180px;
        // min-height: 180px;
        // width: 17vw;
        max-width: 100px; // Adjusted min-width for mobile
        max-height: 100px; // Adjusted min-height for mobile
      }

        // Styles for laptop
      @media (min-width: 738px) {
        max-width: 50vh;

        // min-width: 270px;
        // min-height: 280px;
        // width: 20vw;
      }

      // Styles for desktop
      @media (min-width: 1908px) {
        max-width: 50vh;

        // min-width: 250px;
        // min-height: 280px;
        // width: 17vw;
      }

      .img-container{
 

              // Styles for mobile
        @media (max-width: 767px) {
          // Mobile-specific styles here
          min-width: 150px;
          min-height: 180px;
          // width: 10vw;
          border-radius: 10px;

        }
    // Styles for laptop
    @media (min-width: 738px) {
      // width:  12vw;; /* Set the desired width */
      // height: 100%; /* Set the desired height */
      border-radius: 15px;
   
      max-height: 30vh;
    }

    // Styles for desktop
    @media (min-width: 1908px) {
       
          // width:  9vw;; /* Set the desired width */
          // height: 12vw; /* Set the desired height */
          border-radius: 15px;
       
          max-height: 25vh;
        }

        position: relative; 
        border: 5px solid white; /* Add a white border */
        box-sizing: border-box;  
        overflow: hidden; /* Hide overflowed parts */

      }
      // height: 30vh;
      background-color: #ff66b3;
      animation: pulseCenter 6s infinite;
      z-index: 5;
      img{
        transform: scaleX(-100%);
        // width: 100%; /* Make the image fill the width */
        // height: 100%; /* Make the image fill the height */
        object-fit: cover; /* Crop the image to fill the container */
        position: absolute; /* Position it absolutely */
        top: 0;
        left: 0;
      }
    }
  }
  
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.06);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes pulseCenter {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.07);
    }
    100% {
      transform: scale(1);
    }
  }