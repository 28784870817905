/* width */
::-webkit-scrollbar {
    width: 10px;
    }

/* Track */
::-webkit-scrollbar-track {
    background: #ffffff;
    
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #999999;
    border-radius: 40px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: rgb(51, 51, 51);
}